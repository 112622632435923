import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotfoundComponent implements OnInit {

  constructor(private router: Router, private location: Location) {
  }

  ngOnInit() {
  }

  backClicked() {
    console.log(this.router.navigated);
    this.location.back();
  }

}
