import {Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {DataService} from '../data.service';
import {ActivatedRoute, Router} from '@angular/router';
// import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-newsdetail',
  templateUrl: './newsdetail.component.html',
  styleUrls: ['./newsdetail.component.scss']
})
export class NewsdetailComponent implements OnInit, OnDestroy {

  newsItem: any;
  newsItemId: any;
  isNewsItemReady: boolean = false;
  currentLang: string;
  subscription: any = [];
  newsItemType: string;
  newsList: any;

  // state$: Observable<object>;

  constructor(
    private translate: TranslateService,
    private location: Location,
    private data: DataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient
  ) {

    const subscriptionConst = this.translate.onLangChange
      .subscribe((event: LangChangeEvent) => {
        console.log(event.lang + ' 2');
        this.getnewsItem(this.newsItemType, event.lang, this.newsItemId);
      });

    this.subscription.push(subscriptionConst);

  }


  backClicked() {
    this.location.back();
  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(params => {
      this.newsItemType = params.newsType;
      this.newsItemId = params.newsId;
      this.currentLang = params.lang;
      this.getnewsItem(this.newsItemType, this.currentLang, this.newsItemId);
    });


    // this is to use with the dataservice
    // this.newsItem = this.data.getCurrentNewsItem(this.newsItemId);

    // using state$ variable
    // this.state$ = this.activatedRoute.paramMap
    //   .pipe(map(() => window.history.state));

  }

  getnewsItem(type, lang, id) {
    this.translate.use(lang);
    if (lang !== 'zh' && lang !== 'tb') {
      this.router.navigate(['**']);
    } else {
      const subscriptionConst = this.httpClient.get('/assets/i18n/' + type + '/' + lang + '.json')
        .subscribe(data => {
          this.newsList = data;
          if (type === 'zenannews') {
            this.newsList = this.newsList.zenanNewsItems;
          } else if (type === 'industrynews') {
            this.newsList = this.newsList.industryNewsItems;
          }
          if (id > this.newsList.length) {
            this.router.navigate(['**']);
          } else {
            this.newsItem = this.newsList[id - 1];
            this.isNewsItemReady = true;
          }
        });
      this.subscription.push(subscriptionConst);
    }
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }
}
