import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import {map, catchError} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {Newsitem} from './newsitem';
import {Config} from 'protractor';

const headers = new HttpHeaders()
  .set('Content-Type', 'application/json');

@Injectable({
  providedIn: 'root'
})

export class DataService {

  currentLang: string = this.translate.currentLang;
  previousList: any;
  newList: any;
  subscription: any = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private httpClient: HttpClient
  ) {
  }

// this is for the Newsdetail component if using DataService
// getCurrentNewsItem(index) {
//   return zenanNewsList.zenanNewsItems[index - 1];
// }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  getCurrentLang() {
    return this.translate.currentLang;
  }

  updateLang(lang) {
    this.translate.use(lang);
    this.currentLang = lang;
    // console.log(this.router.url);
    // this.router.navigate(['/' + this.router.url.split(('/')[0])[1] + '/' + lang]);
  }

  /*  getNewsitem() {
      return this.httpClient.get('../assets/i18n/zenannews/zh.json')
    }*/


  getNewsitem(): Observable<HttpResponse<Config>> {
    return this.httpClient.get<Config>(
      '../assets/i18n/zenannews/zh.json', {observe: 'response'}
    );
  }

  addNewsitem(newsitem: Newsitem): Observable<Newsitem> {
    return this.httpClient.post<Newsitem>(
      '../assets/i18n/zenannews/zh.json', newsitem)
      .pipe(
        // catchError(this.handleError('addNewsItem', newsitem))
      );
  }

  updateNewsList(newsItem: Newsitem): Observable<Newsitem> {
    console.log(newsItem);
    return this.httpClient.put<Newsitem>(
      '../assets/i18n/zenannews/zh.json', newsItem)
  }

  addZenanNews(lang, news) {
    if (lang === 'zh') {
      import('../assets/i18n/zenannews/zh.json').then(data => {
        this.previousList = data.default;
        news.newsId = this.previousList.zenanNewsItems.length;
        this.newList = this.previousList.zenanNewsItems.concat(news);
        console.log('---', this.newList);
        return this.httpClient.post('../assets/i18n/zenannews/zh.json', news);
      });
    } else if (lang === 'tb') {
      import('../assets/i18n/zenannews/tb.json').then(data => {
        this.previousList = data.default;
        news.newsId = this.previousList.zenanNewsItems.length;
        this.newList = this.previousList.zenanNewsItems.concat(news);
        console.log('---', this.newList);
        return this.httpClient.post('../assets/i18n/zenannews/tb.json', news);
      });
    }
  }

  /*    this.subscription = this.httpClient.get(url).subscribe(data => {
        this.previousList = data;
      news.newsId = this.previousList.zenanNewsItems.length;
      this.newList = this.previousList.zenanNewsItems.concat(news);
      console.log('new list is ', this.newList);


      this.httpClient.post<any>(url, news)
        .subscribe(value => {
          console.log(value);
        });
    }*/


  addIndustryNews(lang, news) {
    if (lang === 'zh') {
      import('../assets/i18n/industrynews/zh.json').then(data => {
        this.previousList = data.default.industryNewsItems;
        news.newsId = this.previousList.length;
        this.newList = this.previousList.concat(news);
        console.log('---', this.newList);
      });
    } else if (lang === 'tb') {
      import('../assets/i18n/industrynews/tb.json').then(data => {
        this.previousList = data.default.industryNewsItems;
        news.newsId = this.previousList.length;
        this.newList = this.previousList.industryNewsItems.concat(news);
        console.log('---', this.newList);
      });
    }

    /*    const url = '/assets/i18n/industrynews/' + lang + '.json';
        this.subscription = this.httpClient.get(url).subscribe(data => {
          this.previousList = data;
          // console.log('previous list is ', this.previousList);
          news.newsId = this.previousList.industryNewsItems.length;
          this.newList = this.previousList.industryNewsItems.concat(news);
          console.log('new list is ', this.newList);
        });*/
  }
}
