import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Location} from '@angular/common';

@Component({
  selector: 'app-jobform',
  templateUrl: './jobform.component.html',
  styleUrls: ['./jobform.component.scss']
})
export class JobformComponent implements OnInit {

  newJobItem: any = new FormGroup({
    jobTitle: new FormControl(''),
    jobCount: new FormControl(''),
    jobDescription: new FormControl('')
  });

  constructor(private location: Location) {
  }

  ngOnInit() {
  }


  insertNewJobItem(job) {
    this.newJobItem.jobTitle.setValue(job.jobTitle);
    console.log(this.newJobItem);
  }

  backClicked() {
    this.location.back();
  }

}
