import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
// import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';

import {ReactiveFormsModule} from '@angular/forms';

import {FontAwesomeModule} from '@fortawesome/fontawesome-free';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NavComponent} from './nav/nav.component';
import {HomeComponent} from './home/home.component';
import {FooterComponent} from './footer/footer.component';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {JobsComponent} from './jobs/jobs.component';
import {JobformComponent} from './jobform/jobform.component';
import {ZenannewsformComponent} from './zenannewsform/zenannewsform.component';
import { NewsdetailComponent } from './newsdetail/newsdetail.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { NewsformComponent } from './newsform/newsform.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  // return new TranslateHttpLoader(http);
  return new MultiTranslateHttpLoader(http, [
    {prefix: './assets/i18n/main/', suffix: '.json'},
    {prefix: './assets/i18n/zenannews/', suffix: '.json'},
    {prefix: './assets/i18n/industrynews/', suffix: '.json'},
    {prefix: './assets/i18n/jobs/', suffix: '.json'}
  ]);
}

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,
    FooterComponent,
    JobsComponent,
    JobformComponent,
    ZenannewsformComponent,
    NewsdetailComponent,
    NotfoundComponent,
    NewsformComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
