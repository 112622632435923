import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = '泽南科技';


  constructor(public translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('zh');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('zh');
  }

  onActivate(event) {
    window.scroll(0, 0);
  }

}
