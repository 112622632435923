import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../data.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, OnDestroy {

  isCollapsed: boolean = true;
  currentLang: any;
  subscription: any = [];

  constructor(
    private data: DataService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    const subscriptionConst = this.activatedRoute.queryParams.subscribe(params => {
      this.currentLang = params.lang;
    });

    this.subscription.push(subscriptionConst);

  }

  toggleMenu() {
    this.isCollapsed = !this.isCollapsed;
  }

  changeLanguage(lang) {
    this.currentLang = lang;
    this.data.updateLang(lang);
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }
}
