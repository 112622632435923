import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-zenannewsform',
  templateUrl: './zenannewsform.component.html',
  styleUrls: ['./zenannewsform.component.sass']
})
export class ZenannewsformComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
