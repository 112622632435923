import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../data.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  currentLang: string = this.translate.currentLang;
  subscription: any = [];

  constructor(
    private data: DataService,
    private translate: TranslateService
  ) {

    const subscriptionConst = this.translate.onLangChange
      .subscribe((event: LangChangeEvent) => {
        this.currentLang = event.lang;
      });

    this.subscription.push(subscriptionConst);
  }

  ngOnInit() {
    this.currentLang = this.data.getCurrentLang();
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }
}
