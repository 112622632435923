import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Location} from '@angular/common';
import {DataService} from '../data.service';
import {Newsitem} from '../newsitem';
import {HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

@Component({
  selector: 'app-newsform',
  templateUrl: './newsform.component.html',
  styleUrls: ['./newsform.component.scss']
})
export class NewsformComponent implements OnInit, OnDestroy {

  defaultDate: any = Date.now();
  newnewsItem: any = {};
  newitemList: Newsitem;

  newsItem = new FormGroup({
    newsItemTitle: new FormControl(''),
    newsDate: new FormControl(this.defaultDate),
    newsShortDescription: new FormControl(''),
    newsLongDescription: new FormControl(''),
    imageUrl: new FormControl('')
  });

  newsMeta = new FormGroup({
    newsType: new FormControl(''),
    newsLang: new FormControl('')
  });


  constructor(
    private location: Location,
    private dataService: DataService,
    private httpClient: HttpClient
  ) {
  }

  ngOnInit() {
    this.getnewsItemList();
  }

  getnewsItemList() {
    this.dataService
      .getNewsitem()
      .subscribe((data: any) => {
        console.log(data, 'from newsform');
        this.newitemList = data;
      });
  }

  insertNewsItem() {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    console.log(this.newsItem.value);
    return this.httpClient.put<any>(
      '../assets/i18n/zenannews/zh.json', (this.newsItem.value))
      .subscribe({
          next: data => console.log(data),
          error: error => console.error('There was an error!', error)
        }
      );
  }

  /* insertNewsItem() {
     if (this.newsMeta.value.newsType === 'zenannews') {
       this.dataService.addZenanNews(this.newsMeta.value.newsLang, this.newsItem.value);
     } else if (this.newsMeta.value.newsType === 'industrynews') {
       this.dataService.addIndustryNews(this.newsMeta.value.newsLang, this.newsItem.value);
     }
   }*/

  backClicked() {
    this.location.back();
  }

  ngOnDestroy() {

  }
}

