import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {JobsComponent} from './jobs/jobs.component';
import {JobformComponent} from './jobform/jobform.component';
import {ZenannewsformComponent} from './zenannewsform/zenannewsform.component';
import {NewsdetailComponent} from './newsdetail/newsdetail.component';
import {NotfoundComponent} from './notfound/notfound.component';
import {NewsformComponent} from './newsform/newsform.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'home', component: HomeComponent},
  {path: 'jobs', component: JobsComponent},
  {path: 'jobform', component: JobformComponent},
  {path: 'zenannewsform', component: ZenannewsformComponent},
  {path: 'newsform', component: NewsformComponent},
  {path: 'news', component: NewsdetailComponent},
  {path: '404', component: NotfoundComponent},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
